export function useModal() {
  const isOpen = ref(false)
  const target = ref(null)
  const modalRef = ref<HTMLElement | null>(null)
  const closeButtonRef = ref<HTMLElement | null>(null)
  const previousActiveElement = ref<HTMLElement | null>(null)

  const open = () => {
    isOpen.value = true
    // Store currently focused element
    previousActiveElement.value = document.activeElement as HTMLElement
    // Focus the close button when modal opens
    nextTick(() => {
      closeButtonRef.value?.focus()
    })
  }

  const close = () => {
    isOpen.value = false
    // Restore focus when modal closes
    nextTick(() => {
      previousActiveElement.value?.focus()
    })
  }

  const handleKeydown = (e: KeyboardEvent) => {
    // Handle ESC key
    if (e.key === 'Escape') {
      close()
      return
    }

    // Handle focus trapping with Tab key
    if (!modalRef.value || e.key !== 'Tab') return

    const allFocusableElements = modalRef.value.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    )

    // Filter out hidden elements
    const focusableElements = Array.from(allFocusableElements).filter((element) => {
      const el = element as HTMLElement
      // Check if element or any of its parents are hidden
      let currentEl: HTMLElement | null = el
      while (currentEl) {
        const style = window.getComputedStyle(currentEl)
        if (style.display === 'none' || style.visibility === 'hidden') {
          return false
        }
        currentEl = currentEl.parentElement
      }
      return true
    })

    if (focusableElements.length === 0) return

    const firstFocusable = focusableElements[0] as HTMLElement
    const lastFocusable = focusableElements[focusableElements.length - 1] as HTMLElement

    if (e.shiftKey) {
      if (document.activeElement === firstFocusable) {
        lastFocusable.focus()
        e.preventDefault()
      }
    } else {
      if (document.activeElement === lastFocusable) {
        firstFocusable.focus()
        e.preventDefault()
      }
    }
  }

  onClickOutside(target, close)

  onBeforeUnmount(() => document.documentElement.classList.remove('page-is-frozen'))

  watch(isOpen, (value) => {
    if (value) return document.documentElement.classList.add('page-is-frozen')
    document.documentElement.classList.remove('page-is-frozen')
  })

  return {
    target,
    modalRef,
    closeButtonRef,
    isOpen,
    open,
    close,
    handleKeydown,
  }
}

export const useSearchModal = createSharedComposable(useModal)
export const useCartModal = createSharedComposable(useModal)
export const useLoginModal = createSharedComposable(useModal)
export const useNavigationMobileModal = createSharedComposable(useModal)
export const useAddressModal = createSharedComposable(useModal)
export const useToccoModuleModal = createSharedComposable(useModal)
export const useEmptyModal = createSharedComposable(useModal)
